import { useAppSelector } from "app/store/hooks";
import { useShowPurchaseQuery } from "entities/purchase/purchaseApi";
import { selectUser } from "features/auth/slice/authSlice";
import { Message } from "features/dispute/disputeType";
import { disputesApi, useGetMessagesQuery, useSendMessageMutation } from "features/dispute/disputesApi";
import { useEffect, useState } from "react";
import { FiSend, FiInfo } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import echo from "shared/lib/echo";

interface SkeletonLoaderProps {
    className?: string;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ className }) => (
    <div className={`animate-pulse bg-gray-300 dark:bg-gray-700 ${className || ""}`} />
);


const DisputeChatPage = () => {
    const navigate = useNavigate();
    const { purchaseId } = useParams<{ purchaseId: string }>();
    const {
        data,
        error,
        isLoading: showPurchaseIsLoading,
    } = useShowPurchaseQuery(Number(purchaseId), {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    });
    const [sendMessage] = useSendMessageMutation();
    const [lastMessageId, setLastMessageId] = useState<number | undefined>(undefined);


    const { data: messages, isLoading: isMessagesLoading, refetch, isFetching } = useGetMessagesQuery({
        purchaseId: purchaseId || "",
        lastMessageId,
    }, {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    });
    const [message, setMessage] = useState('');
    const [stateMessages, setStateMessages] = useState<Message[]>([]);
    const me = useAppSelector(selectUser);
    const [linkToProduct, setLinkToProduct] = useState("");

    // useEffect для обработки полученных сообщений без изменения lastMessageId
    useEffect(() => {
        if (messages?.messages) {
            // Сохраняем только новые сообщения
            setStateMessages(prevMessages => [...messages.messages.slice().reverse(), ...prevMessages]);
        }
    }, [messages]);




    const handleLoadMore = () => {
        if (!isFetching && messages?.hasMore) {
            // Обновляем lastMessageId только при наличии новых сообщений
            const lastMessage = stateMessages[0];
            if (lastMessage) {
                const newLastMessageId = lastMessage.id;
                setLastMessageId(newLastMessageId);  // Обновляем lastMessageId

                // После обновления lastMessageId выполняем refetch
                refetch();
            }
        }
    };



    useEffect(() => {
        if (data?.product) {
            if (data.product_type.includes("Image")) {
                setLinkToProduct("/images/" + data.product.id);
            } else if (data.product_type.includes("DigitalProduct")) {
                setLinkToProduct("/digital-products/" + data.product.id);
            } else if (data.product_type.includes("Course")) {
                setLinkToProduct("/courses/" + data.product.id);
            }
        }
    }, [data]);

    useEffect(() => {
        if (!purchaseId) return;


        // Подключаемся к каналу
        const channel = echo.channel(`dispute.${purchaseId}`);
        console.log('Channel connected:', channel);

        // Слушаем событие 'DisputeMessageSent'
        channel.listen('DisputeMessageSent', (event: any) => {
            if (event.message.user.id === me?.id) return;
            setStateMessages((prevMessages) => [...prevMessages, event.message]);
        }).error((error: any) => {
            console.error('Error listening to channel:', error);
        });

        // Вывод ошибок на уровне самого Echo, если есть проблемы с подключением или каналом
        echo.connector.pusher.connection.bind('error', (error: any) => {
            console.error('Pusher connection error:', error);
        });

        return () => {
            channel.stopListening('DisputeMessageSent');
        };
    }, [purchaseId]);


    // Проверка доступа
    useEffect(() => {
        // Дождаться завершения загрузки данных
        if (!showPurchaseIsLoading && data) {
            // Проверка доступа
            if (
                !me ||
                (me.role !== "admin" &&
                    me.role !== "moderator" &&
                    me.id !== data.user_id &&
                    me.id !== data.product.user.id)
            ) {
                navigate("/403"); // Перенаправление на страницу ошибки доступа
            }
        }
    }, [me, data, navigate, showPurchaseIsLoading]);

    const handleSendMessage = async () => {
        if (!message.trim() || !purchaseId || !me) return;

        // Добавление нового сообщения в локальное состояние
        const newMessage = {
            created_at: new Date(),
            message,
            purchase_id: Number(purchaseId),
            user: me,
            user_id: me.id
        };

        setStateMessages((prev) => [...prev, newMessage]);

        try {
            await sendMessage({
                purchaseId,
                message,
            }).unwrap();

            setMessage("");  // Очистить поле ввода после отправки
        } catch (error) {
            console.error("Failed to send message:", error);
            // Откатить изменения в случае ошибки
            setStateMessages((prev) => prev.filter(msg => msg !== newMessage));
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-300 dark:from-gray-900 dark:to-gray-800 text-gray-900 dark:text-gray-100 p-6">
            <div className="max-w-4xl mx-auto space-y-6">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl">
                    <h2 className="text-2xl font-semibold flex items-center space-x-2 border-b border-gray-200 dark:border-gray-700 pb-4 mb-4">
                        <FiInfo className="text-blue-500" />
                        <span>Детали чата</span>
                    </h2>
                    {showPurchaseIsLoading ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <SkeletonLoader className="h-6 w-3/4 bg-gray-200 dark:bg-gray-700" />
                            <SkeletonLoader className="h-6 w-3/4 bg-gray-200 dark:bg-gray-700" />
                            <SkeletonLoader className="h-6 w-3/4 bg-gray-200 dark:bg-gray-700" />
                            <SkeletonLoader className="h-6 w-3/4 bg-gray-200 dark:bg-gray-700" />
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div><strong>Продукт:</strong> <Link to={linkToProduct} className="text-blue-500 dark:text-blue-400 underline">{data?.product.title}</Link></div>
                            <div><strong>Покупатель:</strong> <Link to={`/profiles/${data?.user.id}`} className="text-blue-500 dark:text-blue-400 underline">{data?.user.name}</Link></div>
                            <div><strong>Продавец:</strong> <Link to={`/profiles/${data?.product.user.id}`} className="text-blue-500 dark:text-blue-400 underline">{data?.product.user.name}</Link></div>
                            <div><strong>Статус:</strong> <span className="text-yellow-600 dark:text-yellow-400">{data?.status}</span></div>
                        </div>
                    )}
                </div>

                {/* ЧАТ */}
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl">
                    <div className="overflow-y-auto max-h-[400px] space-y-4 custom-scrollbar transition-all duration-300 ease-in-out">
                        <button
                            onClick={handleLoadMore}
                            className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-sm text-gray-600 dark:text-gray-300 rounded-full shadow-md hover:bg-gray-300 dark:hover:bg-gray-600">
                            {isFetching ? "Загрузка..." : "Загрузить предыдущие сообщения"}
                        </button>
                        {isMessagesLoading ? (
                            Array.from({ length: 5 }).map((_, idx) => (
                                <SkeletonLoader key={idx} className="h-16 w-full bg-gray-200 dark:bg-gray-700 rounded-lg" />
                            ))
                        ) : (
                            stateMessages.map((msg, idx) => {
                                const showDateDivider =
                                    idx === 0 ||
                                    new Date(msg.created_at).toDateString() !== new Date(stateMessages[idx - 1]?.created_at).toDateString();

                                return (
                                    <div key={idx} className="space-y-2 transition-all duration-500 ease-in-out opacity-0 animate-opacity" style={{ opacity: 1 }}>
                                        {showDateDivider && (
                                            <div className="text-center text-gray-500 dark:text-gray-400 text-sm mt-4">
                                                {new Date(msg.created_at).toLocaleDateString()}
                                            </div>
                                        )}
                                        <div
                                            className={`p-4 rounded-lg shadow-md w-fit max-w-[80%] ${msg.user_id === me?.id
                                                ? "bg-blue-200 dark:bg-blue-700 ml-auto"
                                                : "bg-gray-200 dark:bg-gray-600"
                                                }`}
                                        >
                                            <span className="font-semibold">
                                                {msg.user_id === me?.id ? "Вы" : msg.user.name}
                                            </span>
                                            <p className="text-sm mt-1">{msg.message}</p>
                                            <span className="text-xs text-gray-500 dark:text-gray-400">
                                                {new Date(msg.created_at).toLocaleTimeString()}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>

                </div>

                {/* ПОЛЕ ВВОДА */}
                <div className="flex items-center gap-2">
                    <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                        className="flex-1 p-3 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 shadow-md focus:ring focus:ring-blue-500 focus:outline-none"
                        placeholder="Введите сообщение..."
                    />
                    <button
                        onClick={handleSendMessage}
                        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600">
                        <FiSend />
                        <span className="ml-2">Отправить</span>
                    </button>
                </div>
            </div>
        </div>
    );

};

export default DisputeChatPage;
