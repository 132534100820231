import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTools } from 'react-icons/fa';

const InDevelopmentPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Возвращаемся на предыдущую страницу
  };

  return (
    <div className="min-h-[81vh] bg-gradient-to-r from-purple-600 to-blue-500 dark:from-purple-800 dark:to-blue-900 text-white flex items-center justify-center">
      <div className="text-center p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-lg w-full">
        <FaTools className="text-6xl text-blue-500 mb-4 mx-auto" />
        <h1 className="text-4xl font-semibold mb-4 text-gray-900 dark:text-white">
          В процессе создания
        </h1>
        <p className="text-lg mb-6 text-gray-700 dark:text-gray-300">
          Эта страница еще в разработке. Пожалуйста, загляните позже!
        </p>
        <div className="flex justify-center space-x-4">
          <button
            onClick={handleGoBack}
            className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
          >
            Назад
          </button>
        </div>
      </div>
    </div>
  );
};

export default InDevelopmentPage;
