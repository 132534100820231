import React from "react";
import { useGetLatestUserAgreementQuery } from "entities/userAgreement/userAgreementApi"; // Импортируем хук для получения соглашения

const UserAgreementPage = () => {
  // Получаем данные о последнем соглашении с использованием хука
  const { data, error, isLoading } = useGetLatestUserAgreementQuery();

  // Показываем скелетон загрузки, пока идет запрос
  if (isLoading) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">Пользовательское соглашение</h1>
        <div className="space-y-4">
          <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded w-2/3 animate-pulse"></div>
          <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-full animate-pulse"></div>
          <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-5/6 animate-pulse"></div>
        </div>
      </div>
    );
  }

  // Обработка ошибки при загрузке данных
  if (error) {
    return <div className="text-center text-red-500">Ошибка при получении соглашения</div>;
  }

  // Обработка случая, если agreement_text не существует
  const agreementText = data?.data?.agreement_text ?? ''; // Если данных нет, используем пустую строку

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">Пользовательское соглашение</h1>
      <div className="prose dark:prose-invert bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        {/* Используем dangerouslySetInnerHTML для отображения HTML контента */}
        <div dangerouslySetInnerHTML={{ __html: agreementText }} />
      </div>
    </div>
  );
};

export default UserAgreementPage;
