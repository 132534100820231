import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { useGetDigitalProductQuery } from "entities/digital-product/digitalProductsApi";
import {
  useGetMyPurchaseByProductQuery,
  useInitDisputeMutation,
  usePurchaseMutation,
} from "entities/purchase/purchaseApi";
import { selectUser, setUser } from "features/auth/slice/authSlice";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "shared/ui/Loading";

const DigitalProductDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const productId = parseInt(id || "0");
  const {
    data: product,
    isLoading,
    error,
  } = useGetDigitalProductQuery(productId, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const [purchaseDigitalProduct, { isLoading: purchasing }] =
    usePurchaseMutation();

  const [initDispute, { isLoading: isDisputeLoading }] =
    useInitDisputeMutation();
  const {
    data: myPurchase,
    isLoading: myPurchaseLoading,
  } = useGetMyPurchaseByProductQuery(
    {
      productType: "digital_product",
      productId: Number(id),
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );
  const [isPending, setIsPending] = useState(false);
  const handleInitiateDispute = async () => {
    if (
      !myPurchase ||
      (myPurchase.status !== "pending" && myPurchase.status !== "completed")
    ) {
      toast.warn("Доступ к спорам только для активных покупок.");
      return;
    }

    try {
      await initDispute(myPurchase.id).unwrap();
      toast.success("Диспут инициирован. Администрация свяжется с вами.");
    } catch (error) {
      toast.error("Ошибка при инициировании диспута. Попробуйте снова.");
    }
  };
  const me = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading || myPurchaseLoading) return;

    if (error) {
      toast.error("Ошибка при загрузке данных продукта.");
    }
  }, [error, isLoading, me, myPurchaseLoading]);

  if (isLoading || myPurchaseLoading) {
    return <Loading />;
  }

  if (!product) {
    return <p>Product not found.</p>;
  }

  const handlePurchaseProduct = async () => {
    if (!me) {
      toast.error(
        <div>
          Пожалуйста,{" "}
          <Link to="/auth/login" className="text-blue-500 underline">
            авторизуйтесь
          </Link>{" "}
          для покупки продукта.
        </div>
      );
      return;
    }

    if (product.user.id === me.id) {
      toast.warn("You cannot purchase your own product.");
      return;
    }

    if (parseFloat(me.balance) < product.price) {
      toast.error("Insufficient funds to purchase this product.");
      return;
    }

    if (!product.is_active) {
      toast.error("Этот продукт не доступен для покупки.");
      return;
    }

    if (product.quantity <= 0) {
      toast.error("Этот продукт временно отсутствует на складе.");
      return;
    }

    try {
      const { message } = await purchaseDigitalProduct({
        productId: product.id,
        productType: "digital_product",
      }).unwrap();
      dispatch(setUser({
        ...me,
        balance: String(+me.balance - +product.price)
      }))
      toast.success(message);
      setIsPending(true);
    } catch (error: any) {
      toast.error("Ошибка при покупке продукта. Попробуйте еще раз.");
    } 
  };

  const renderPurchaseStatus = () => {
    if (!myPurchase && !isPending && me && me.id !== product.user_id) {
      return (
        <button
          onClick={handlePurchaseProduct}
          disabled={purchasing || !product.is_active || product.quantity <= 0}
          className={`w-full ${
            purchasing || !product.is_active || product.quantity <= 0
              ? "bg-gray-400"
              : "bg-green-600"
          } hover:${
            purchasing || !product.is_active || product.quantity <= 0
              ? "bg-gray-400"
              : "bg-green-700"
          } text-white font-semibold py-3 px-6 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300`}
        >
          {purchasing ? <span>Обработка...</span> : "Приобретение продукта"}
        </button>
      );
    }

    if (isPending) {
      return (
        <p className="mt-4 text-yellow-600 text-lg font-semibold">
          Ваша покупка находится в процессе.
        </p>
      );
    }

    if (!myPurchase) return;

    const { status } = myPurchase;

    if (status === "dispute") {
      return (
        <p className="mt-2 text-purple-500">
          Вы инициировали диспут. Ожидайте решения администрации.
          <br />
          <Link className="text-red-500 underline" to={"/dispute-chats/" + myPurchase.id}>Перейти</Link>  к чату диспута
        </p>
      );
    }

    if (status === "completed") {
      return (
        <div className="mt-4">
          <p className="text-green-600 text-lg font-semibold">
            Спасибо за покупку!
          </p>
          <button
            onClick={handleDownload}
            className="mt-2 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          >
            Скачать файл продукта
          </button>
          <button
            disabled={isDisputeLoading}
            onClick={handleInitiateDispute}
            className="mt-4 bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-200"
          >
            Инициировать диспут
          </button>
        </div>
      );
    }

    if (status === "pending" || isPending) {
      return (
        <p className="mt-4 text-yellow-600 text-lg font-semibold">
          Ваша покупка находится в процессе.
        </p>
      );
    }

    if (status === "canceled") {
      return (
        <p className="mt-4 text-red-600 text-lg font-semibold">
          Ваша покупка была отменена.
        </p>
      );
    }

    return null;
  };

  const handleDownload = async (event: React.MouseEvent) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API
        }/digital_contents/${product.file_path.replace(
          "digital_contents/",
          ""
        )}/file`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      const blob = await response.blob();
      const fileExtension = product.file_path.split(".").pop();
      const fileName =
        product.file_path.replace("images/", "") || "downloaded-file";

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${fileName}.${fileExtension}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("Продукт успешно скачан!");
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Не удалось скачать цифровой товар. Попробуйте еще раз.");
    }
  };

  return (
    <div className="my-5 container mx-auto p-6 max-w-lg bg-white dark:bg-gray-800 shadow-lg rounded-xl">
      <h1 className="text-4xl font-bold text-gray-800 dark:text-white">
        {product.title}
      </h1>
      <p className="mt-3 text-gray-600 dark:text-gray-400">
        {product.description}
      </p>
      <div className="mt-4 flex items-center justify-between">
        <p className="text-2xl font-semibold text-green-700">
          ${product.price}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          Категория - {product.category}
        </p>
      </div>

      {/* Display Available Quantity */}
      {product.is_active && product.quantity > 0 && (
        <p className="mt-4 text-gray-600 dark:text-gray-400">
          В наличии: {product.quantity}{" "}
          {product.quantity === 1 ? "единица" : "единицы"}
        </p>
      )}

      <p className="mt-4 text-gray-500 dark:text-gray-400">
        Загружено пользователем:{" "}
        <Link
          to={`/profiles/${product.user.id}`}
          className="text-blue-500 hover:underline"
        >
          {product.user.name}
        </Link>
      </p>

      <div className="mt-8">
        {product.user.id === me?.id && (
          <div>
            <p className="mt-4 text-gray-600">
              Вы являетесь автором этого продукта.
            </p>
            <button
              onClick={handleDownload}
              className="mt-2 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            >
              Скачать файл продукта
            </button>
          </div>
        )}
        {renderPurchaseStatus()}
      </div>
    </div>
  );
};

export default DigitalProductDetailPage;
