import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { Message, MessagesResponse } from "./disputeType";

const baseUrl = `${process.env.REACT_APP_API}/disputes`; // Your base URL for disputes

export const disputesApi = createApi({
  reducerPath: "disputesApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Send a new message in the dispute chat
    sendMessage: builder.mutation<Message, { purchaseId: string; message: string; }>({
      query: ({ purchaseId, message }) => ({
        url: `${purchaseId}/messages`, // Adjust this path as needed
        method: "POST",
        body: {
          message,
        },
      }),
    }),
    getMessages: builder.query<MessagesResponse , { purchaseId: string, lastMessageId?: number; }>({
      query: ({purchaseId, lastMessageId}) => ({
        url: `${purchaseId}/messages`, // Adjust this path as needed
        method: "GET",
        params: {
          last_message_id: lastMessageId
        },
        keepUnusedDataFor: 60, // Данные кэшируются на 60 секунд
      }),
    }),
  }),
});

export const { useSendMessageMutation, useGetMessagesQuery } = disputesApi;
