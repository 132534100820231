import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";

const baseUrl = `${process.env.REACT_APP_API}/news`; // Замените на ваш базовый URL

// API-сервис
export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["News"], // Используется для рефетча
  endpoints: (builder) => ({
    getNews: builder.query({
      query: ({
        page = 1,
        per_page = 10,
        sort_field = "created_at",
        sort_order = "desc",
      }) =>
        `?page=${page}&per_page=${per_page}&sort_field=${sort_field}&sort_order=${sort_order}`,
      providesTags: ["News"],
    }),

    getNewsById: builder.query({
      query: (id: number) => `news/${id}`,
      providesTags: (result, error, id) => [{ type: "News", id }],
    }),
    createNews: builder.mutation({
      query: (newNews) => ({
        url: "",
        method: "POST",
        body: newNews,
      }),
      invalidatesTags: ["News"],
    }),
    updateNews: builder.mutation({
      query: ({ id, ...updatedNews }) => ({
        url: `/${id}`,
        method: "PUT",
        body: updatedNews,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "News", id }],
    }),
    deleteNews: builder.mutation({
      query: (id: number) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "News", id }],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useGetNewsByIdQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation,
} = newsApi;
