import React from "react";
import { useGetUsersSelledPurchasesQuery } from "entities/purchase/purchaseApi"; // adjust according to your file structure

const UsersSelledPurchasesPage = () => {
  const { data: purchases, isLoading, isError } = useGetUsersSelledPurchasesQuery();

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading purchases.</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-4">Users' Selled Purchases</h1>
      <table className="min-w-full table-auto">
        <thead className="bg-gray-100 dark:bg-gray-800 text-left">
          <tr>
            <th className="py-2 px-4 border-b">Product</th>
            <th className="py-2 px-4 border-b">Price</th>
            <th className="py-2 px-4 border-b">Status</th>
            <th className="py-2 px-4 border-b">Chat</th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-900">
          {purchases?.map((purchase) => (
            <tr key={purchase.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <td className="py-2 px-4 border-b">{purchase.product?.title || "Unnamed Product"}</td>
              <td className="py-2 px-4 border-b">{purchase.price}</td>
              <td className="py-2 px-4 border-b">{purchase.status}</td>
              <td className="py-2 px-4 border-b">
                {purchase.status === "dispute" ? (
                  <a
                    href={`/dispute-chats/${purchase.id}`}
                    className="text-blue-600 dark:text-blue-400 hover:underline"
                  >
                    Go to Chat
                  </a>
                ) : (
                  "No Dispute"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersSelledPurchasesPage;
