import React, { useState, useEffect } from "react";
import { useGetUsersQuery } from "entities/user/api/usersApi";

type Props = {};

const UsersPage = (props: Props) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(""); // Состояние для поиска
    const [triggerSearch, setTriggerSearch] = useState(true); // Флаг для контроля выполнения запроса

    // Запрос с параметрами поиска и страницы
    const { data, isLoading, isFetching, error, refetch } = useGetUsersQuery(
        { page, search },
        { refetchOnFocus: true, skip: !triggerSearch } // Не пропускаем запрос
    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value); // Обновляем только состояние поиска
        setTriggerSearch(false); // Не запускаем запрос сразу
    };

    const handleSearchClick = () => {
        setTriggerSearch(true); // Запускаем запрос по кнопке
    };

    useEffect(() => {
        if (triggerSearch) {
            refetch(); // Перезапуск запроса при клике на кнопку "Поиск"
        }
    }, [triggerSearch, refetch]);

    if (error) {
        return (
            <div className="text-center py-10 text-red-500">
                Произошла ошибка при загрузке пользователей.
            </div>
        );
    }

    const currentPage = data?.links?.find((link) => link.active)?.label ?? 1;
    const lastPage = data?.last_page ?? 1;

    const handlePrevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNextPage = () => {
        if (page < lastPage) setPage(page + 1);
    };

    return (
        <div className="p-5 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
            <h1 className="text-2xl font-semibold mb-5 text-center text-gray-900 dark:text-white">
                Список пользователей
            </h1>

            {/* Поле для поиска и кнопка в одной строке */}
            <div className="mb-5 flex space-x-2">
                <input
                    type="text"
                    placeholder="Поиск по имени или email"
                    value={search}
                    onChange={handleSearchChange}
                    className="input input-bordered w-full dark:bg-gray-800 dark:text-white"
                />
                <button
                    onClick={handleSearchClick}
                    className="btn btn-primary dark:btn-accent text-white"
                >
                    Поиск
                </button>
            </div>

            <div className="overflow-x-auto rounded-lg shadow-md">
                <table className="table w-full dark:table-dark">
                    <thead className="bg-gray-100 dark:bg-gray-800">
                        <tr>
                            <th className="text-left text-gray-900 dark:text-gray-200">#</th>
                            <th className="text-left text-gray-900 dark:text-gray-200">Имя</th>
                            <th className="text-left text-gray-900 dark:text-gray-200">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading || isFetching ? (
                            // Скелетоны, если данные еще загружаются
                            new Array(10).fill(null).map(() => (
                                <tr>
                                    <td className="py-3 px-4">
                                        <div className="h-4 bg-gray-300 rounded-md animate-pulse"></div>
                                    </td>
                                    <td className="py-3 px-4">
                                        <div className="h-4 bg-gray-300 rounded-md animate-pulse"></div>
                                    </td>
                                    <td className="py-3 px-4">
                                        <div className="h-4 bg-gray-300 rounded-md animate-pulse"></div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            data?.data.map((user, index) => (
                                <tr
                                    key={user.id}
                                    className="transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                                >
                                    <td className="text-gray-900 dark:text-gray-200">{user.id}</td>
                                    <td className="text-gray-900 dark:text-gray-200">{user.name}</td>
                                    <td className="text-gray-900 dark:text-gray-200">{user.email}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <div className="flex justify-between items-center mt-5">
                <button
                    className="btn btn-primary dark:btn-accent text-white border-none hover:bg-blue-600"
                    onClick={handlePrevPage}
                    disabled={page === 1}
                >
                    Назад
                </button>
                <p className="text-gray-900 dark:text-gray-200">
                    Страница {page} из {lastPage}
                </p>
                <button
                    className="btn btn-primary dark:btn-accent text-white border-none hover:bg-blue-600"
                    onClick={handleNextPage}
                    disabled={page === lastPage}
                >
                    Вперед
                </button>
            </div>
        </div>
    );
};

export default UsersPage;
