import { useAppDispatch } from "app/store/hooks";
import { useShowMyProfileQuery } from "entities/user/api/profileApi";
import { setUser } from "features/auth/slice/authSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loading from "shared/ui/Loading";
import Header from "widgets/header";
import SubHeader from "widgets/sub-header";

type Props = {};

const Layout = (props: Props) => {
  const { data, error, isLoading } = useShowMyProfileQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // Пользователь переключился с вкладки — замутить контент
        document.body.style.filter = "blur(10px)";
      } else {
        // Вернуть оригинальный вид
        document.body.style.filter = "none";
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(setUser(data));
    }
  }, [data, dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col dark:bg-gray-900 bg-white min-h-screen dark:text-gray-100">
      {" "}
      {/* Контейнер занимает всю высоту экрана */}
      <Header />
      <SubHeader />
      <div className="flex-1 overflow-y-auto">
        {" "}
        {/* Основной контент с возможностью прокрутки */}
        <Outlet />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Layout;
