import { useAppDispatch, useAppSelector } from "app/store/hooks";
import {
  useCreateCommentMutation,
  useGetCommentsQuery,
} from "entities/comment/commentApi";
import { CreateCommentResponseItem } from "entities/comment/commentTypes";
import {
  useDestroyImageMutation,
  useDownloadImageMutation,
  useGetImageDetailQuery,
} from "entities/image/imagesApi";
import {
  useGetMyPurchaseByProductQuery,
  useInitDisputeMutation,
  usePurchaseMutation,
} from "entities/purchase/purchaseApi";
import { selectUser, setUser } from "features/auth/slice/authSlice";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "shared/ui/Loading";

const filePaths = process.env.REACT_APP_PUBLIC_PATH;

const ImageDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: myPurchase,
    isLoading: myPurchaseLoading,
    error: purchaseError,
  } = useGetMyPurchaseByProductQuery(
    {
      productType: "image",
      productId: Number(id),
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );
  const {
    data,
    isLoading: imageLoading,
    error: imageError,
  } = useGetImageDetailQuery(Number(id), {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const navigate = useNavigate();
  const {
    data: dataComments,
    isLoading: commentsLoading,
    error: commentsError,
  } = useGetCommentsQuery(data ? data.id : -1, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const [downloadImage] = useDownloadImageMutation();

  const [initDispute, { isLoading: isDisputeLoading }] =
    useInitDisputeMutation();
  const [createComment, { isLoading: isCommentCreating }] =
    useCreateCommentMutation();
  const [purchaseImage, { isLoading: isPurchasing }] = usePurchaseMutation();
  const [destroyImage, { isLoading: isDestroying }] = useDestroyImageMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<number | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);


  const handleDeleteImage = (imageId: number) => {
    setImageToDelete(imageId);
    setIsDeleteModalOpen(true);
  };


  const confirmDelete = async () => {
    if (imageToDelete === null) return;

    try {
      await destroyImage(imageToDelete).unwrap();
      toast.success("Изображение успешно удалено.");
      setIsDeleteModalOpen(false);
      navigate("/");
    } catch (error) {
      toast.error("Ошибка при удалении изображения.");
    }
  };

  const cancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const dispatch = useAppDispatch();
  const [commentContent, setCommentContent] = useState("");
  const [comments, setComments] = useState<CreateCommentResponseItem[]>(
    dataComments ? dataComments : []
  );
  const [isPending, setIsPending] = useState(false);
  const me = useAppSelector(selectUser);
  const isAuthorized = !!me?.id;
  const isAuthor = me?.id === data?.user.id;

  useEffect(() => {
    if (!data?.path || !data?.watermark_path) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const imagePath = data.path ?? data.watermark_path;
    const image = new Image();

    image.src = `${filePaths}${imagePath}`;

    // Проверка на загрузку изображения
    image.onload = () => {
      const aspectRatio = image.width / image.height;
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientWidth / aspectRatio;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      setImageLoaded(true);
    };

    // Очистить состояние загрузки, если изображение не загружено
    image.onerror = () => {
      console.error("Ошибка загрузки изображения");
      setImageLoaded(false);
    };

    return () => {
      setImageLoaded(false);
    };
  }, [data, filePaths, canvasRef.current]);



  useEffect(() => {
    if (dataComments) {
      setComments(dataComments); // Update comments when dataComments changes
    }
  }, [dataComments]);

  const handleCreateComment = async () => {
    if (!commentContent.trim() || isCommentCreating || !isAuthorized) return;

    try {
      const newComment = await createComment({
        content: commentContent,
        imageId: data?.id ?? -1,
      }).unwrap();
      setComments((prev) => [...prev, newComment.comment]);
      toast.success(newComment.message);
      setCommentContent("");
    } catch (error: any) {
      toast.error(
        error.data?.message ||
        "Ошибка при отправке комментария. Попробуйте снова."
      );
    }
  };

  const handlePurchaseImage = async () => {
    if (!isAuthorized) {
      toast.error(
        <div>
          Пожалуйста,{" "}
          <Link to="/auth/login" className="text-blue-500 underline">
            авторизуйтесь
          </Link>{" "}
          для покупки изображения.
        </div>
      );

      return;
    }

    if (!data) {
      toast.error("Не удалось загрузить картинку");
      return;
    }

    if (data.user.id === me?.id) {
      toast.warn("Вы не можете купить собственное изображение.");
      return;
    }

    if (!myPurchase) {
      try {
        const { message } = await purchaseImage({
          productId: data.id,
          productType: "image",
        }).unwrap();
        dispatch(setUser({
          ...me,
          balance: String(+me.balance - +data.price)
        }))
        setIsPending(true);
        toast.success(message);
      } catch (error: any) {
        let message = "Ошибка при покупке изображения. Попробуйте снова.";
        if (error.data.error) {
          message = error.data.error;
        }
        toast.error(message);
      }
      return;
    }

    if (myPurchase.status === "completed") {
      toast.info("Вы уже купили это изображение.");
      return;
    }

    if (myPurchase.status === "pending") {
      toast.info("Ваша покупка уже находится в процессе.");
      return;
    } else if (myPurchase.status === "canceled") {
      toast.warn("Ваша предыдущая покупка была отменена. Попробуйте снова.");
    }

    if (parseFloat(me.balance) < parseFloat(data.price)) {
      toast.error("Недостаточно средств для покупки изображения.");
      return;
    }
  };

  const handleInitiateDispute = async () => {
    if (!isAuthorized || !data || !myPurchase) {
      toast.warn("Доступ к спорам только для активных покупок.");
      return;
    }

    try {
      // Assuming dispute initiation creates a dispute and returns an ID
      await initDispute(myPurchase.id).unwrap();
      toast.success("Диспут инициирован. Администрация свяжется с вами.");
    } catch (error: any) {
      console.log(error);
      toast.error("Ошибка при инициировании диспута.");
    }
  };


  const renderPurchaseStatus = () => {
    if (!myPurchase && !isPending && !isAuthor && data && +data.price > 0) {
      return (
        <button
          onClick={handlePurchaseImage}
          className="mt-2 bg-green-500 text-white p-2 rounded cursor-pointer"
          disabled={isPurchasing}
        >
          {isPurchasing ? "Загрузка..." : "Купить изображение"}
        </button>
      );
    }

    if (isPending) {
      return (
        <p className="mt-2 text-yellow-500">
          Ваша покупка находится в процессе.
        </p>
      );
    }

    if (!myPurchase) return;
    const { status } = myPurchase;

    if (status === "pending" || isPending) {
      return (
        <p className="mt-2 text-yellow-500">
          Ваша покупка находится в процессе.
        </p>
      );
    }

    if (status === "dispute" && data) {
      return (
        <p className="mt-2 text-purple-500">
          Вы инициировали диспут. Ожидайте решения администрации.
          <br />
          <Link className="text-red-500 underline" to={"/dispute-chats/" + myPurchase.id}>Перейти</Link>  к чату диспута
        </p>
      );
    }

    if (status === "completed" && data && +data.price > 0) {
      return (
        <>
          <p className="mt-2 text-green-500">Вы уже купили это изображение.</p>
          <button
            onClick={handleInitiateDispute}
            className="mt-2 bg-red-500 text-white p-2 rounded"
            disabled={isDisputeLoading}
          >
            Инициировать диспут
          </button>
        </>
      );
    }

    if (status === "canceled") {
      return <p className="mt-2 text-red-500">Ваша покупка была отменена.</p>;
    }

    return null;
  };

  if (imageLoading || myPurchaseLoading || commentsLoading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Данные изображения не найдены
      </div>
    );
  }

  const handleDownload = async (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent redirect

    try {
      // Определим путь к файлу
      const filePath = data.path ?? data.watermark_path;
      const response = await fetch(
        `${process.env.REACT_APP_API}/${filePath}/file`
      );

      // Проверим, если запрос не успешен, выбрасываем ошибку
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      const blob = await response.blob();

      // Извлечем расширение файла
      const fileExtension = filePath.split(".").pop() || "jpg"; // Если не найдено расширение, установим по умолчанию

      // Имя файла
      const fileName = "photo";

      // Создаем объект URL для скачивания
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${fileName}.${fileExtension}`;

      // Инициализируем скачивание
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Освобождаем ресурсы
      window.URL.revokeObjectURL(url);

      // Уведомляем сервер о скачивании
      await downloadImage(data.id).unwrap();

      // Уведомляем пользователя об успешном скачивании
      toast.success("Изображение успешно скачано!");
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Не удалось скачать изображение. Попробуйте еще раз.");
    }
  };

  const { path, price, is_18_plus, watermark, watermark_path } = data;
  const isFreeOrCompleted = +data.price <= 0 || (myPurchase && myPurchase.status === "completed");
  const canDownload = isFreeOrCompleted || isAuthor;

  return (
    <div className="container mx-auto p-4 lg:p-8">
      {/* В render-функции (внутри компонента): */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">
              Вы уверены, что хотите удалить изображение?
            </h3>
            <div className="flex justify-end space-x-4">
              <button
                disabled={isDestroying}
                onClick={cancelDelete}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Отменить
              </button>
              <button
                onClick={confirmDelete}
                disabled={isDestroying}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col lg:flex-row gap-8 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <div className="flex-1 relative">
          <div className="relative rounded-lg w-full shadow-lg">

            <canvas
              ref={canvasRef}
              className={`w-full h-auto ${is_18_plus ? "blur-lg" : ""}`}
              style={{
                opacity:
                  watermark &&
                    myPurchase &&
                    myPurchase.status !== "completed" &&
                    data.user.id !== me?.id
                    ? 0.9
                    : 1,
              }}
              onContextMenu={(e) => e.preventDefault()} // Отключаем контекстное меню
            ></canvas>


            {watermark &&
              myPurchase?.status !== "completed" &&
              data.user.id !== me?.id && (
                <div
                  className="absolute inset-0"
                  style={{
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="100px" width="100px"><text transform="translate(20, 100) rotate(-30)" fill="rgba(128,128,128, 0.3)" font-size="20">RULU</text></svg>')`,
                    backgroundRepeat: "repeat",
                  }}
                />
              )}
            {is_18_plus &&
              myPurchase &&
              myPurchase.status !== "completed" &&
              data.user.id !== me?.id && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 text-white text-xl font-bold ">
                  18+ Content
                </div>
              )}
          </div>
        </div>

        <div className="flex-1 text-gray-800 dark:text-gray-200">
          <h2 className="text-3xl font-bold mb-4">{data.title}</h2>
          <p className="text-lg mb-2">
            <span className="font-semibold">Цена:</span> ${price}
          </p>

          {isAuthor && (
            <>
              <p className="mt-2 text-gray-500">
                Вы являетесь автором этого изображения.
              </p>
              <div className="mt-4">
                <Link
                  to={`/images/edit/${data.id}`}
                  className="bg-yellow-500 text-white p-2 rounded inline-block hover:bg-yellow-600"
                >
                  Редактировать изображение
                </Link>
              </div>
              <div className="mt-4">
                <button
                  onClick={() => handleDeleteImage(data.id)}
                  className="bg-red-500 text-white p-2 rounded inline-block hover:bg-red-600"
                >
                  Удалить изображение
                </button>
              </div>
            </>
          )}
          {renderPurchaseStatus()}

          {/* Download Button */}
          {canDownload && (
            <>
              <div className="mt-4">
                <button
                  onClick={handleDownload}
                  className=" bg-blue-500 text-white p-2 rounded"
                >
                  Скачать изображение
                </button>
              </div>
            </>
          )}

          {comments.length > 0 ? (
            <div className="mt-8">
              <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">Комментарии:</h3>
              <ul className="mt-4 space-y-4">
                {comments.map((comment) => (
                  <li
                    key={comment.id}
                    className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md flex items-start space-x-4 transition-all duration-300 hover:shadow-xl"
                  >
                    <div className="w-12 h-12 rounded-full border-2 border-blue-500 flex items-center justify-center bg-gray-300 dark:bg-gray-600">
                      {comment.user.avatar ? (
                        <img
                          src={comment.user.avatar}
                          alt={comment.user.name}
                          className="w-12 h-12 rounded-full object-cover"
                        />
                      ) : (
                        <span className="text-xl font-semibold text-white">
                          {comment.user.name[0].toUpperCase()}
                        </span>
                      )}
                    </div>
                    <div className="flex-1">
                      <p className="text-gray-900 dark:text-gray-100 font-semibold">{comment.user.name}</p>
                      <p className="mt-2 text-gray-700 dark:text-gray-300">{comment.content}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}



          {isAuthorized && (
            <div className="mt-8">
              <textarea
                value={commentContent}
                onChange={(e) => setCommentContent(e.target.value)}
                placeholder="Ваш комментарий"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
              <button
                disabled={isCommentCreating}
                onClick={handleCreateComment}
                className="mt-2 bg-blue-500 text-white p-2 rounded"
              >
                {!isCommentCreating ? "Оставить комментарий" : "Загрузка..."}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageDetailPage;
