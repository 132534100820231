import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { useShowCourseQuery } from "entities/course/coursesApi";
import {
  useGetMyPurchaseByProductQuery,
  usePurchaseMutation,
} from "entities/purchase/purchaseApi";
import { useGetVideosListQuery } from "entities/video-course/videoc-course-api";
import { selectUser, setUser } from "features/auth/slice/authSlice";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "shared/ui/Loading";

type Props = {};

const DemoVideos = (props: Props) => {
  const { id } = useParams();
  const {
    data: course,
    error: courseError,
    isLoading: courseIsLoading,
  } = useShowCourseQuery(Number(id));
  const [isPending, setIsPending] = useState(false);
  const { data, error, isLoading } = useGetVideosListQuery({
    is_demo: true,
    course_id: Number(id),
  });
  const dispatch = useAppDispatch();
  const me = useAppSelector(selectUser);
  const [purchase, { isLoading: isPurchaseLoading }] = usePurchaseMutation();
  const {
    data: myPurchase,
    isLoading: myPurchaseLoading,
    error: purchaseError,
  } = useGetMyPurchaseByProductQuery(
    {
      productType: "course",
      productId: Number(id),
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );
  const isAuthorized = !!me?.id;

  useEffect(() => {
    if (isLoading || myPurchaseLoading) return;

    if (error) {
      toast.error("Ошибка при загрузке данных продукта.");
    }
  }, [course, error, isLoading, me, myPurchaseLoading, purchaseError]);

  const handlePurchase = async () => {
    if (!course || !isAuthorized) {
      toast.error(
        <div>
          Пожалуйста,{" "}
          <Link to="/auth/login" className="text-blue-500 underline">
            авторизуйтесь
          </Link>{" "}
          для покупки курса.
        </div>
      );
      return;
    }

    if (course.user.id === me?.id) {
      toast.warn("Вы не можете купить собственное курс.");
      return;
    }

    if (!myPurchase) {
      try {
        const { message } = await purchase({
          productId: course.id,
          productType: "course",
        }).unwrap();
        setIsPending(true);
        toast.success(message);
        dispatch(setUser({
          ...me,
          balance: String(+me.balance - +course.price)
        }))
      } catch (error: any) {
        toast.error("Ошибка при покупке курса. Попробуйте снова.");
      }
      return;
    }

    if (myPurchase.status === "completed") {
      toast.info("Вы уже купили этот курс.");
      return;
    }

    if (myPurchase.status === "pending") {
      toast.info("Ваша покупка уже находится в процессе.");
      return;
    } else if (myPurchase.status === "canceled") {
      toast.warn("Ваша предыдущая покупка была отменена. Попробуйте снова.");
    }

    if (parseFloat(me.balance) < parseFloat(course.price)) {
      toast.error("Недостаточно средств для покупки курса.");
      return;
    }
  };

  const renderPurchaseStatus = () => {
    if (!myPurchase && !isPending && me && me.id !== course?.user_id) {
      return (
        <button
          onClick={handlePurchase}
          disabled={isPurchaseLoading || isPending}
          className={`w-full mt-5 ${
            isPurchaseLoading || isPending ? "bg-gray-400" : "bg-green-500"
          } hover:${
            isPurchaseLoading || isPending ? "bg-gray-400" : "bg-green-600"
          } text-white font-semibold py-2 px-4 rounded transition duration-200`}
        >
          {isPurchaseLoading ? <span>Обработка...</span> : "Купить"}
        </button>
      );
    }

    if (isPending) {
      return (
        <p className="mt-2 text-yellow-500">
          Ваша покупка находится в процессе.
        </p>
      );
    }

    if (course && course.user.id === me?.id) {
      return (
        <div className="my-6 flex">
          <Link
            to={`/courses/${course.id}`}
            className="px-8 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none transition-all duration-300"
          >
            Перейти к полному курсу
          </Link>
        </div>
      );
    }

    if (!myPurchase) return;
    const { status } = myPurchase;

    if (status === "dispute") {
      return (
        <p className="mt-2 text-purple-500">
          Вы инициировали диспут. Ожидайте решения администрации.
        </p>
      );
    }

    if (course && (status === "completed" || course.user.id === me?.id)) {
      return (
        <div className="my-6 flex">
          <Link
            to={`/courses/${course.id}`}
            className="px-8 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none transition-all duration-300"
          >
            Перейти к полному курсу
          </Link>
        </div>
      );
    }

    if (status === "pending") {
      return (
        <p className="mt-2 text-yellow-500">
          Ваша покупка находится в процессе.
        </p>
      );
    }

    if (status === "canceled") {
      return <p className="mt-2 text-red-500">Ваша покупка была отменена.</p>;
    }

    return null;
  };

  if (courseIsLoading || isLoading || myPurchaseLoading || isPurchaseLoading) {
    return <Loading />;
  }

  if (courseError || error) {
    return (
      <div className="text-center py-8 text-red-600">
        Ошибка при загрузке курса или демо-видео!
      </div>
    );
  }

  if (!course) {
    return <div className="text-center py-8 text-red-600">Курс не найден!</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-semibold text-gray-800 dark:text-white mb-6">
        Демо-видео для курса {course.title}
      </h1>

      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg mb-8 transition-all duration-300">
        <h2 className="text-3xl font-bold text-gray-800 dark:text-white">
          {course.title}
        </h2>
        <p className="text-gray-600 dark:text-gray-400 mt-2">
          {course.description}
        </p>
        <p className="text-gray-600 dark:text-gray-400 mt-2 text-lg font-semibold">
          Цена: {course.price} $
        </p>
        <p className="text-gray-600 dark:text-gray-400 mt-2 text-sm">
          Рейтинг: {course.rating}
        </p>

        {renderPurchaseStatus()}
      </div>

      {data && data.length > 0 ? (
        <ul className="space-y-8">
          {data.map((video) => (
            <li
              key={video.id}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6"
            >
              <div className="flex-grow">
                <h4 className="text-xl font-semibold text-gray-800 dark:text-white flex items-center">
                  {video.title}
                  {video.is_demo && (
                    <span className="ml-2 px-2 py-1 text-xs font-bold text-white bg-yellow-500 rounded-full">
                      Демо
                    </span>
                  )}
                </h4>
                <p className="text-gray-600 dark:text-gray-400 text-sm mt-2">
                  Дата добавления:{" "}
                  {new Date(video.created_at).toLocaleDateString()}
                </p>
              </div>
              <Link
                to={`/videos/${video.id}`}
                className="px-6 py-3 text-white bg-green-500 rounded-lg hover:bg-green-600 focus:outline-none transition-colors duration-300 mt-4 md:mt-0"
              >
                Смотреть видео
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-center text-gray-600 mt-8">
          Нет доступных демо-видео для этого курса.
        </div>
      )}
    </div>
  );
};

export default DemoVideos;
