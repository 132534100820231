import { useAppDispatch } from "app/store/hooks";
import { useRegisterMutation } from "features/auth/authApi";
import { setToken, setUser } from "features/auth/slice/authSlice";
import { useEffect, useState } from "react";
import { FaFacebookF, FaGoogle, FaTwitter, FaVk } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // import styles

type Props = {};

const RegisterPage = (props: Props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const [register] = useRegisterMutation();


  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    const user = query.get("user");
    const error = query.get("error");

    if (error) {
      toast.error(decodeURIComponent(error)); // Use toast for errors
    }

    if (token) {
      localStorage.setItem("authToken", token);
      dispatch(setToken(token));
      if (user) {
        localStorage.setItem("user", user);
        dispatch(setUser(JSON.parse(user)));
      }

      navigate("/");
    }
  }, [navigate, dispatch]);

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    if (password !== confirmPassword) {
      toast.error("Пароли не совпадают."); // Use toast for password mismatch
      setIsLoading(false); // Stop loading if passwords do not match
      return;
    }

    try {
      await register({
        email,
        password,
        name,
      }).unwrap();

      toast.success("Регистрация успешна! Проверьте свою почту для подтверждения.");
      setName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");

      navigate("/auth/login");
    } catch (error: any) {
      if (error.data && error.data.errors) {
        const errorMessages = Object.values(error.data.errors).flat() as string[];
        errorMessages.forEach((msg) => toast.error(msg));
      } else {
        toast.error("Произошла ошибка при регистрации.");
      }
    } finally {
      setIsLoading(false); // Stop loading after success or failure
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-4">
      <h1 className="text-4xl font-bold mb-8">Регистрация</h1>

      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md space-y-6">
        <form onSubmit={handleRegister} className="space-y-4">
          <input
            type="text"
            placeholder="Имя"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input input-bordered input-primary w-full bg-base-100 text-base-content"
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input input-bordered input-primary w-full bg-base-100 text-base-content"
            required
          />
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input input-bordered input-primary w-full bg-base-100 text-base-content"
            required
          />
          <input
            type="password"
            placeholder="Подтвердите пароль"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="input input-bordered input-primary w-full bg-base-100 text-base-content"
            required
          />
          <button
            type="submit"
            disabled={isLoading} // Disable button during loading
            className={`w-full ${isLoading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
              } text-white py-2 rounded transition duration-300`}
          >
            {isLoading ? "Загрузка..." : "Зарегистрироваться"}
          </button>
        </form>

        <div className="flex items-center justify-center my-4">
          <span className="h-px w-full bg-gray-300 dark:bg-gray-600" />
          <span className="px-2 text-gray-500 dark:text-gray-400 text-sm">
            или
          </span>
          <span className="h-px w-full bg-gray-300 dark:bg-gray-600" />
        </div>

        <div className="space-y-2">
          <a
            href={`${process.env.REACT_APP_API}/auth/google/redirect`}
            className="flex items-center justify-center bg-red-500 hover:bg-red-600 text-white py-2 rounded-lg shadow-md transition duration-300 transform hover:scale-105"
          >
            <FaGoogle className="mr-2" /> Войти с Google
          </a>
          <a
            href={`${process.env.REACT_APP_API}/auth/facebook/redirect`}
            className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-lg shadow-md transition duration-300 transform hover:scale-105"
          >
            <FaFacebookF className="mr-2" /> Войти с Facebook
          </a>
          <a
            href={`${process.env.REACT_APP_API}/auth/twitter/redirect`}
            className="flex items-center justify-center bg-blue-400 hover:bg-blue-500 text-white py-2 rounded-lg shadow-md transition duration-300 transform hover:scale-105"
          >
            <FaTwitter className="mr-2" /> Войти с Twitter
          </a>
          <a
            href={`${process.env.REACT_APP_API}/auth/vk/redirect`}
            className="flex items-center justify-center bg-blue-700 hover:bg-blue-800 text-white py-2 rounded-lg shadow-md transition duration-300 transform hover:scale-105"
          >
            <FaVk className="mr-2" /> Войти с VK
          </a>
        </div>

        <p className="text-center text-sm text-gray-500 dark:text-gray-400 mt-6">
          Уже есть аккаунт?{" "}
          <Link
            to="/auth/login"
            className="text-blue-500 hover:underline dark:text-blue-400"
          >
            Войти
          </Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterPage;
