import React, { useState } from 'react';
import { useGetAllFaqsQuery } from 'entities/faq/faqApi';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FaqsPage = () => {
  const { data, error, isLoading } = useGetAllFaqsQuery();
  const [openFaqId, setOpenFaqId] = useState<number | null>(null);

  const toggleFaq = (faqId: number) => {
    setOpenFaqId(openFaqId === faqId ? null : faqId);
  };

  if (error) return <div className="text-center py-4 text-red-600">Произошла ошибка</div>;

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold mb-6 dark:text-white text-center">
        Часто задаваемые вопросы
      </h1>

      <div className="space-y-4">
        {isLoading
          ? Array(5).fill(0).map((_, index) => (
              <div
                key={index}
                className="bg-gray-800 text-white rounded-lg shadow-md overflow-hidden animate-pulse"
              >
                <div className="accordion-header p-4">
                  <div className="w-full h-6 bg-gray-600 rounded"></div>
                </div>
                <div className="accordion-collapse overflow-hidden max-h-0 p-0"></div>
              </div>
            ))
          : data?.map((faq) => (
              <div
                key={faq.id}
                className="accordion-item bg-gray-800 text-white rounded-lg shadow-md overflow-hidden"
              >
                <h2 className="accordion-header">
                  <button
                    className="accordion-button w-full text-left p-4 hover:bg-gray-700 focus:outline-none"
                    type="button"
                    onClick={() => toggleFaq(faq.id)}
                  >
                    <span className="text-lg font-medium flex items-center justify-between">
                      {faq.question}
                      <span>
                        {openFaqId === faq.id ? (
                          <FaChevronUp className="text-xl text-gray-300" />
                        ) : (
                          <FaChevronDown className="text-xl text-gray-300" />
                        )}
                      </span>
                    </span>
                  </button>
                </h2>
                <div
                  className={`accordion-collapse overflow-hidden transition-all duration-300 ease-in-out ${
                    openFaqId === faq.id ? 'max-h-screen p-4' : 'max-h-0 p-0'
                  }`}
                >
                  <div className="accordion-body text-sm text-gray-300">{faq.answer}</div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default FaqsPage;
