import React from 'react';
import { FaNewspaper, FaRegHandshake, FaQuestionCircle, FaStar, FaServicestack, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';

type Props = {};

const CommunityPage = (props: Props) => {
  return (
    <div className="min-h-[80vh] bg-gray-900 text-white p-6">
      <div className="container mx-auto text-center">
        <h1 className="text-4xl font-semibold mb-8">Сообщество</h1>
        <p className="mb-6 text-lg">Добро пожаловать на страницу сообщества! Здесь вы найдете ссылки на важные разделы нашего сайта.</p>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div className="card bg-gray-800 text-center shadow-lg rounded-lg">
            <div className="card-body">
              <FaNewspaper className="text-4xl mb-4 mx-auto" />
              <h3 className="text-xl font-medium mb-2">Новости</h3>
              <Link to="/news" className="text-blue-400 hover:text-blue-600 transition duration-300">Перейти</Link>
            </div>
          </div>

          <div className="card bg-gray-800 text-center shadow-lg rounded-lg">
            <div className="card-body">
              <FaRegHandshake className="text-4xl mb-4 mx-auto" />
              <h3 className="text-xl font-medium mb-2">Пользовательское соглашение</h3>
              <Link to="/user-agreement" className="text-blue-400 hover:text-blue-600 transition duration-300">Перейти</Link>
            </div>
          </div>

          <div className="card bg-gray-800 text-center shadow-lg rounded-lg">
            <div className="card-body">
              <FaQuestionCircle className="text-4xl mb-4 mx-auto" />
              <h3 className="text-xl font-medium mb-2">Помощь</h3>
              <Link to="/faqs" className="text-blue-400 hover:text-blue-600 transition duration-300">Перейти</Link>
            </div>
          </div>

          <div className="card bg-gray-800 text-center shadow-lg rounded-lg">
            <div className="card-body">
              <FaStar className="text-4xl mb-4 mx-auto" />
              <h3 className="text-xl font-medium mb-2">Рецензии</h3>
              <Link to="/reviews" className="text-blue-400 hover:text-blue-600 transition duration-300">Перейти</Link>
            </div>
          </div>

          <div className="card bg-gray-800 text-center shadow-lg rounded-lg">
            <div className="card-body">
              <FaServicestack className="text-4xl mb-4 mx-auto" />
              <h3 className="text-xl font-medium mb-2">Услуги</h3>
              <Link to="/services" className="text-blue-400 hover:text-blue-600 transition duration-300">Перейти</Link>
            </div>
          </div>

          <div className="card bg-gray-800 text-center shadow-lg rounded-lg">
            <div className="card-body">
              <FaUsers className="text-4xl mb-4 mx-auto" />
              <h3 className="text-xl font-medium mb-2">Пользователи</h3>
              <Link to="/users" className="text-blue-400 hover:text-blue-600 transition duration-300">Перейти</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityPage;
