import React, { useState } from 'react';
import { useGetNewsQuery } from 'entities/news/newsApi';
import { FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Иконки для сортировки

const NewsPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState('created_at'); // Поле сортировки
  const [sortOrder, setSortOrder] = useState('desc'); // Порядок сортировки
  const perPage = 5; // Количество новостей на страницу

  // Получаем данные через RTK Query
  const { data: newsData, isLoading, isError, isFetching } = useGetNewsQuery({
    page: currentPage,
    per_page: perPage,
    sort_field: sortField,
    sort_order: sortOrder,
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSortChange = (field: string) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-300 dark:from-gray-800 dark:to-gray-900 p-4">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-extrabold mb-8 text-gray-900 dark:text-gray-100 text-center border-b-2 border-gray-300 dark:border-gray-700 pb-4">
          Новости
        </h1>

        {/* Сортировка */}
        <div className="flex justify-end mb-4 space-x-2">
          <button
            onClick={() => handleSortChange('created_at')}
            className={`px-4 py-2 flex items-center space-x-2 rounded-lg ${sortField === 'created_at'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
              }`}
          >
            <span>Сортировать по дате</span>
            {sortField === 'created_at' && (
              sortOrder === 'asc' ? <FaChevronUp /> : <FaChevronDown />
            )}
          </button>
          <button
            onClick={() => handleSortChange('title')}
            className={`px-4 py-2 flex items-center space-x-2 rounded-lg ${sortField === 'title'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
              }`}
          >
            <span>Сортировать по заголовку</span>
            {sortField === 'title' && (
              sortOrder === 'asc' ? <FaChevronUp /> : <FaChevronDown />
            )}
          </button>
        </div>

        {/* Новости */}
        {isLoading || isFetching ? (
          <div className="space-y-4">
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                className="bg-gray-200 dark:bg-gray-700 animate-pulse h-20 rounded-md"
              ></div>
            ))}
          </div>
        ) : isError ? (
          <div className="text-center text-red-500">Ошибка загрузки новостей</div>
        ) : newsData?.data.length ? (
          newsData.data.map((item: any) => (
            <div
              key={item.id}
              className="bg-white dark:bg-gray-700 p-6 mb-6 rounded-lg shadow-md"
            >
              <h2 className="text-2xl font-bold">{item.title}</h2>
              <p className="text-sm text-gray-500">
                Дата: {new Date(item.created_at).toLocaleDateString()}
              </p>
              <p>{item.content}</p>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">Новостей нет</div>
        )}


      {/* Пагинация */}
      {newsData?.links && (
          <div className="flex justify-center mt-8 space-x-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg disabled:opacity-50 flex items-center justify-center"
            >
              <FaChevronLeft />
            </button>
            {newsData.links.map((link: any) => (
              link.label !== 'Next »' && link.label !== 'Previous «' && (
                <button
                  key={link.label}
                  onClick={() => handlePageChange(link.label === 'Next »' || link.label === 'Previous «' ? currentPage : parseInt(link.label))}
                  disabled={link.active}
                  className={`px-4 py-2 ${link.active ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-md mx-1`}
                >
                  {link.label === '&laquo; Previous' ? <FaChevronLeft /> : link.label === 'Next &raquo;' ? <FaChevronRight /> : link.label}
                </button>
              )
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === newsData.last_page}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg disabled:opacity-50 flex items-center justify-center"
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsPage;
