import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { UserAgreementResponse } from "entities/userAgreement/types"; // Импортировать типы, связанные с userAgreement

const baseUrl = `${process.env.REACT_APP_API}/user-agreement`; // Замените на ваш базовый URL

export const userAgreementApi = createApi({
  reducerPath: "userAgreementApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLatestUserAgreement: builder.query<UserAgreementResponse, void>({
      query: () => "/latest", // Путь для получения последнего соглашения
    }),
  }),
});

export const { useGetLatestUserAgreementQuery } = userAgreementApi;
