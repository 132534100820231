import { configureStore } from "@reduxjs/toolkit";
import { categoryApi } from "entities/category/categoryApi";
import { chatApi } from "entities/chat/chatApi";
import { commentsApi } from "entities/comment/commentApi";
import { coursesApi } from "entities/course/coursesApi";
import { digitalProductsApi } from "entities/digital-product/digitalProductsApi";
import { faqApi } from "entities/faq/faqApi";
import { imagesApi } from "entities/image/imagesApi";
import imagesReducer from "entities/image/imagesSlice";
import { likesApi } from "entities/like/likesApi";
import { newsApi } from "entities/news/newsApi";
import { notificationsApi } from "entities/notification/notificationApi";
import { purchaseReviewApi } from "entities/purchase-review/purchaseReviewApi";
import { purchaseApi } from "entities/purchase/purchaseApi";
import { subsectionsApi } from "entities/subsection/subsectionApi";
import { profileApi } from "entities/user/api/profileApi";
import { usersApi } from "entities/user/api/usersApi";
import { userAgreementApi } from "entities/userAgreement/userAgreementApi";
import { videosApi } from "entities/video-course/videoc-course-api";
import { authApi } from "features/auth/authApi";
import authReducer from "features/auth/slice/authSlice";
import { balanceApi } from "features/balance/balanceApi";
import chatReducer from "features/chat/chatSlice";
import { disputesApi } from "features/dispute/disputesApi";
import { openrouterApi } from "features/openrouter/openrouterApi";
import openrouterReducer from "features/openrouter/openrouterSlice";
import { telegramWalletApi } from "features/telegram-wallet/telegramWalletApi";

export const store = configureStore({
  reducer: {
    [profileApi.reducerPath]: profileApi.reducer,
    [openrouterApi.reducerPath]: openrouterApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [imagesApi.reducerPath]: imagesApi.reducer,
    [commentsApi.reducerPath]: commentsApi.reducer,
    [purchaseApi.reducerPath]: purchaseApi.reducer,
    [balanceApi.reducerPath]: balanceApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [purchaseReviewApi.reducerPath]: purchaseReviewApi.reducer,
    [digitalProductsApi.reducerPath]: digitalProductsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [telegramWalletApi.reducerPath]: telegramWalletApi.reducer,
    [likesApi.reducerPath]: likesApi.reducer,
    [coursesApi.reducerPath]: coursesApi.reducer,
    [subsectionsApi.reducerPath]: subsectionsApi.reducer,
    [videosApi.reducerPath]: videosApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [disputesApi.reducerPath]: disputesApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [userAgreementApi.reducerPath]: userAgreementApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    auth: authReducer,
    chat: chatReducer,
    openrouter: openrouterReducer,
    images: imagesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      profileApi.middleware,
      openrouterApi.middleware,
      chatApi.middleware,
      imagesApi.middleware,
      commentsApi.middleware,
      purchaseApi.middleware,
      balanceApi.middleware,
      notificationsApi.middleware,
      purchaseReviewApi.middleware,
      digitalProductsApi.middleware,
      authApi.middleware,
      telegramWalletApi.middleware,
      likesApi.middleware,
      coursesApi.middleware,
      subsectionsApi.middleware,
      videosApi.middleware,
      categoryApi.middleware,
      disputesApi.middleware,
      newsApi.middleware,
      usersApi.middleware,
      userAgreementApi.middleware,
      faqApi.middleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
