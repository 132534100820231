import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { FaqResponse } from "./types";

const baseUrl = `${process.env.REACT_APP_API}/faqs`; // Замените на ваш базовый URL

export const faqApi = createApi({
  reducerPath: "faqApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllFaqs: builder.query<FaqResponse[], void>({
      query: () => "/", // Путь для получения всех FAQ
    }),
  }),
});

export const { useGetAllFaqsQuery } = faqApi;
