import React from "react";

type Props = {
  user: {
    avatar?: string;
    name?: string;
  };
};

const Avatar = ({ user }: Props) => {
  return (
    <>
      {user?.avatar ? (
        <img
          src={user.avatar} // Укажите путь к аватару пользователя
          alt="Avatar"
          className="w-8 h-8 rounded-full cursor-pointer"
        />
      ) : (
        <div
          className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 text-white cursor-pointer"
        >
          {user?.name && user?.name.charAt(0)}{" "}
          {/* Используйте первую букву имени пользователя */}
        </div>
      )}
    </>
  );
};

export default Avatar;
