import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { UserResponse } from "entities/user/types";

const baseUrl = `${process.env.REACT_APP_API}/users`; // Замените на ваш базовый URL

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Новый эндпоинт для получения списка пользователей с поиском и пагинацией
    getUsers: builder.query<UserResponse, { search?: string; page?: number }>({
      query: ({ search, page }) => ({
        url: `/`,
        params: {
          search,
          page,
        },
      }),
    }),
  }),
});

export const { useGetUsersQuery } = usersApi;
