import { useAppSelector } from "app/store/hooks";
import { Course } from "entities/course/coursesType";
import { DigitalProduct } from "entities/digital-product/digitalProductTypes";
import { GetImagesResponseItem } from "entities/image/imagesType";
import ImageItem from "entities/image/ui/ImageItem";
import {
  useConfirmPurchaseMutation,
  useShowPurchaseQuery,
} from "entities/purchase/purchaseApi";
import { ShowProfileResponse } from "entities/user/types";
import { selectUser } from "features/auth/slice/authSlice";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "shared/ui/Loading";

const truncateDescription = (description: string, maxLength: number = 150) => {
  if (description.length > maxLength) {
    return description.slice(0, maxLength) + "...";
  }
  return description;
};

const PurchaseDecisionPage = () => {
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const [confirmPurchase, { isLoading }] = useConfirmPurchaseMutation();
  const {
    data,
    error,
    isLoading: showPurchaseIsLoading,
    refetch, // Получаем функцию refetch
  } = useShowPurchaseQuery(Number(purchaseId), {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const user = useAppSelector(selectUser);
  const [image, setImage] = useState<GetImagesResponseItem>();
  const [digitalProduct, setDigitalProduct] = useState<DigitalProduct>();
  const [course, setCourse] = useState<Course>();

  useEffect(() => {
    if (data?.product) {
      if (data.product_type.includes("Image")) {
        setImage(data.product as GetImagesResponseItem);
      } else if (data.product_type.includes("DigitalProduct")) {
        setDigitalProduct(data.product as DigitalProduct);
      } else if (data.product_type.includes("Course")) {
        setCourse(data.product as Course);
      }
    }
  }, [data]);

  const handleDecision = async (action: "confirm" | "cancel") => {
    try {
      await confirmPurchase({
        purchaseId: Number(purchaseId),
        action,
      }).unwrap();
      toast.success(
        action === "confirm"
          ? "Покупка успешно подтверждена."
          : "Покупка успешно отменена."
      );
      // Обновляем данные покупки после мутации
      refetch(); // Повторно загружаем данные после того как покупка была подтверждена или отменена
    } catch (error) {
      toast.error("Не удалось выполнить действие. Попробуйте снова.");
    }
  };

  if (showPurchaseIsLoading) return <Loading />;
  if (error) return <p>Ошибка загрузки данных покупки.</p>;

  // Checking if the user already made a decision
  const isDecisionMade =
    data?.status === "completed" || data?.status === "canceled";

  return (
    <div className="container mx-auto p-6 max-w-2xl">
      <h2 className="text-2xl font-bold mb-6 text-center">
        Решение по покупке
      </h2>
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        {isDecisionMade ? (
          <p className="text-center text-gray-800 dark:text-gray-200 mb-4">
            {data.status === "completed"
              ? "Вы уже подтвердили покупку."
              : "Вы уже отменили покупку."}
          </p>
        ) : (
          <>
            <p className="text-gray-800 dark:text-gray-200 mb-4 text-center">
              Подтвердите или отмените покупку изображения.
            </p>
            <div className="flex justify-center gap-4 mb-4">
              <button
                onClick={() => handleDecision("confirm")}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
                disabled={isLoading || isDecisionMade}  // Блокируем кнопку, если решение уже принято
              >
                Подтвердить покупку
              </button>
              <button
                onClick={() => handleDecision("cancel")}
                className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
                disabled={isLoading || isDecisionMade}  // Блокируем кнопку, если решение уже принято
              >
                Отменить покупку
              </button>
            </div>
          </>
        )}

        {image && user ? (
          <ImageDisplay image={image} user={user} />
        ) : digitalProduct ? (
          <DigitalProductDisplay digitalProduct={digitalProduct} />
        ) : course ? (
          <CourseDisplay course={course} />
        ) : (
          <p className="text-center">Изображение недоступно для просмотра.</p>
        )}
      </div>
    </div>
  );
};


// Component for displaying Image details
const ImageDisplay = ({
  image,
  user,
}: {
  image: GetImagesResponseItem;
  user: ShowProfileResponse;
}) => (
  <div className="flex flex-col items-center">
    <ImageItem
      key={image.id}
      {...image}
      is_disputed={
        user && image.disputed_purchases
          ? image.disputed_purchases.some((p) => p.user_id === user.id)
          : false
      }
      is_canceled={
        user
          ? image.canceled_purchases?.some((p) => p.user_id === user.id)
          : false
      }
      is_pending={
        user
          ? image.pending_purchases?.some((p) => p.user_id === user.id)
          : false
      }
      is_purchased={
        user
          ? image.completed_purchases?.some((p) => p.user_id === user.id)
          : false
      }
      is_liked={user ? image.likes?.some((l) => l.user_id === user.id) : false}
      is_disliked={
        user ? image.dislikes?.some((l) => l.user_id === user.id) : false
      }
    />
  </div>
);

// Component for displaying DigitalProduct details
const DigitalProductDisplay = ({
  digitalProduct,
}: {
  digitalProduct: DigitalProduct;
}) => (
  <Link
    to={`/digital-products/${digitalProduct.id}`}
    key={digitalProduct.id}
    className="border border-gray-300 dark:border-gray-700 p-4 rounded-lg hover:shadow-lg transition-shadow duration-300 bg-white dark:bg-gray-800 w-full max-w-xl mb-4 block"
  >
    <div className="flex justify-between items-center">
      <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
        {digitalProduct.title}
      </h2>
      <p className="text-lg font-semibold text-gray-900 dark:text-gray-100">
        ${digitalProduct.price}
      </p>
    </div>
  </Link>
);

// Component for displaying Course details
const CourseDisplay = ({ course }: { course: Course }) => (
  <div
    key={course.id}
    className="border p-6 rounded-lg shadow-lg transition-all duration-300 ease-in-out transform dark:bg-gray-800 dark:border-gray-700"
  >
    <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">
      {course.title}
    </h3>
    <p className="text-gray-700 dark:text-gray-300 mt-2">
      {truncateDescription(course.description)}
    </p>
    <p className="text-gray-800 dark:text-gray-200 mt-2 font-semibold">
      Price: ${course.price}
    </p>
    <p className="text-gray-500 dark:text-gray-400 mt-1">
      Rating: {course.rating} / 5
    </p>
    <p className="text-gray-600 dark:text-gray-400 mt-1">
      Subsection: {course.subsection.name}
    </p>
    <p className="text-gray-600 dark:text-gray-400 mt-1">
      Author:{" "}
      <Link
        to={"/profiles/" + course.user.id}
        className="text-blue-600 dark:text-blue-400 font-semibold underline hover:text-blue-800 dark:hover:text-blue-300"
      >
        {course.user.name}
      </Link>
    </p>

    <Link
      to={"/courses/" + course.id + "/demo"}
      className="inline-block mt-4 py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none dark:bg-blue-700 dark:hover:bg-blue-600"
    >
      Preview Demo
    </Link>
    <Link
      to={"/courses/" + course.id}
      className="inline-block mt-4 ml-2 py-2 px-4 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none dark:bg-green-700 dark:hover:bg-green-600"
    >
      Видео курса
    </Link>
  </div>
);

export default PurchaseDecisionPage;
