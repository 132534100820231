import { useAppSelector } from "app/store/hooks";
import { useDownloadImageMutation } from "entities/image/imagesApi";
import { GetTopImages } from "entities/image/imagesType";
import { useDeleteLikeMutation, useLikeMutation } from "entities/like/likesApi";
import { selectUser } from "features/auth/slice/authSlice";
import { useEffect, useRef, useState } from "react";
import {
  FaComment,
  FaDownload,
  FaShoppingCart,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const filePaths = process.env.REACT_APP_PUBLIC_PATH;

type Props = {
  topDayImages: GetTopImages;
};

const TopDayImage = ({ topDayImages }: Props) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const user = useAppSelector(selectUser);
  const [likeImage] = useLikeMutation();
  const [deleteLikeImage] = useDeleteLikeMutation();
  const [downloadImage] = useDownloadImageMutation();

  const [imagesState, setImagesState] = useState(
    topDayImages
      ? topDayImages.map((image) => ({
          id: image.id,
          likesCount: image.likes_count,
          dislikesCount: image.dislikes_count,
          downloadsCount: image.downloads_count,
          isLiked: user
            ? image.likes.some((l) => l.user_id === user.id)
            : false,
          isDisliked: user
            ? image.dislikes.some((l) => l.user_id === user.id)
            : false,
          isPurchased: image.is_purchased,
          isAuthor: user && image.user_id === user.id,
          isPending: image.is_pending,
        }))
      : []
  );

  const image = topDayImages[currentSlideIndex];
  const currentImageState = imagesState[currentSlideIndex];
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (!context) return;
      const img = new Image();
      img.src = `${filePaths}${image.path || image.watermark_path}`;
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientWidth / aspectRatio;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    }
  }, [image.path]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlideIndex(
        (prevIndex) => (prevIndex + 1) % topDayImages.length
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [topDayImages]);

  const handleLike = async () => {
    try {
      const newImagesState = [...imagesState];
      const currentImage = newImagesState[currentSlideIndex];

      if (currentImage.isLiked) {
        currentImage.likesCount -= 1;
        currentImage.isLiked = false;
        await deleteLikeImage({
          model_id: image.id,
          model_type: "images",
        });
      } else {
        if (currentImage.isDisliked) {
          currentImage.dislikesCount -= 1;
          currentImage.isDisliked = false;
        }
        currentImage.likesCount += 1;
        currentImage.isLiked = true;
        await likeImage({
          model_id: image.id,
          model_type: "images",
          is_like: true,
        });
      }

      setImagesState(newImagesState);
    } catch (error) {
      console.error("Ошибка при лайке изображения:", error);
    }
  };

  const handleDislike = async () => {
    try {
      const newImagesState = [...imagesState];
      const currentImage = newImagesState[currentSlideIndex];

      if (currentImage.isDisliked) {
        currentImage.dislikesCount -= 1;
        currentImage.isDisliked = false;
        await deleteLikeImage({
          model_id: image.id,
          model_type: "images",
        });
      } else {
        if (currentImage.isLiked) {
          currentImage.likesCount -= 1;
          currentImage.isLiked = false;
        }
        currentImage.dislikesCount += 1;
        currentImage.isDisliked = true;
        await likeImage({
          model_id: image.id,
          model_type: "images",
          is_like: true,
        });
      }

      setImagesState(newImagesState);
    } catch (error) {
      console.error("Ошибка при дизлайке изображения:", error);
    }
  };

  const handleDownload = async () => {
    try {
      await downloadImage(image.id).unwrap();
      const newImagesState = [...imagesState];
      newImagesState[currentSlideIndex].downloadsCount += 1;
      setImagesState(newImagesState);
    } catch (error) {
      console.error("Ошибка при скачивании изображения:", error);
    }
  };

  if (image) {
    return (
      <div className="relative p-4 h-full">
        {/* Author and Status Labels */}
        {currentImageState && currentImageState.isAuthor && (
          <div className="absolute top-2 left-2 z-30 bg-blue-600 text-white font-bold py-1 px-2 rounded">
            Ваше изображение
          </div>
        )}
        {currentImageState && currentImageState.isPurchased && (
          <div className="absolute top-2 right-2 z-30 bg-green-600 text-white font-bold py-1 px-2 rounded">
            Куплено
          </div>
        )}
        {currentImageState && currentImageState.isPending && (
          <div className="absolute top-2 left-2 z-30 bg-yellow-500 text-black font-bold py-1 px-2 rounded">
            Ожидает
          </div>
        )}

        <Link to={`/images/${image.id}`}>
          <canvas
            ref={canvasRef}
            className={`h-full w-full object-cover ${
              image.is_18_plus ? "blur-md" : ""
            }`}
            width="100%"
            height="100%"
            onContextMenu={(e) => e.preventDefault()} // Отключаем контекстное меню
          />
        </Link>

        {/* User Information */}
        <div className="absolute bottom-10 left-0 right-0 p-4 bg-opacity-60 z-20 text-white">
          <Link
            to={`/profiles/${image.user_id}`}
            className="flex items-center space-x-4 bg-opacity-60 bg-gray-800 py-1 p-3"
          >
            {image.user.avatar ? (
              <img
                src={`${image.user.avatar}`}
                alt={image.user.name}
                className="h-7 w-7 object-cover"
              />
            ) : (
              <div className="w-7 h-7 rounded-full bg-gray-500 flex items-center justify-center text-white text-sm font-semibold">
                {image.user.name.charAt(0)}
              </div>
            )}
            <span className="text-sm font-semibold">{image.user.name}</span>
          </Link>
        </div>

        {/* Activity Block */}
        <div className="z-20 absolute bottom-0 left-0 right-0 p-4 bg-opacity-60 bg-gray-800 dark:bg-gray-200 text-white dark:text-black">
          <div className="flex justify-between">
            <div
              onClick={handleLike}
              className={`flex items-center cursor-pointer ${
                currentImageState.isLiked ? "text-blue-500" : ""
              }`}
            >
              <FaThumbsUp className="mr-2" />
              <button>{currentImageState.likesCount}</button>
            </div>
            <div
              onClick={handleDislike}
              className={`flex items-center cursor-pointer ${
                currentImageState.isDisliked ? "text-red-500" : ""
              }`}
            >
              <FaThumbsDown className="mr-2" />
              <button>{currentImageState.dislikesCount}</button>
            </div>
            <Link
              to={`/profiles/${image.user_id}`}
              className="flex items-center cursor-pointer"
            >
              <FaComment className="mr-2" />
              <button>{image.comments_count}</button>
            </Link>
            <div
              onClick={handleDownload}
              className="flex items-center cursor-pointer"
            >
              <FaDownload className="mr-2" />
              <button>{currentImageState.downloadsCount}</button>
            </div>
            <Link
              to={`/images/${image.id}`}
              className={`flex items-center cursor-pointer ${
                currentImageState.isPurchased ? "text-green-500" : ""
              }`}
            >
              <FaShoppingCart className="mr-2" />
              <button
                className={`font-semibold ${
                  currentImageState.isPurchased ? "underline" : ""
                }`}
              >
                {image.purchases_count}
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return <div></div>;
};

export default TopDayImage;
