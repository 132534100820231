import { useGetStatsQuery } from "entities/user/api/profileApi";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for react-toastify

const AssumptionsStatsPage: React.FC = () => {
  const today = new Date().toISOString().split("T")[0];
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDate = tomorrow.toISOString().split("T")[0];
  const [selectedTab, setSelectedTab] = useState<"today" | "month" | "custom">(
    "today"
  );
  const [customDate, setCustomDate] = useState({
    start: today,
    end: tomorrowDate,
  });

  const { data, isLoading, isFetching, error } = useGetStatsQuery(
    {
      dateFilter: selectedTab,
      startDate: selectedTab === "custom" ? customDate.start : undefined,
      endDate: selectedTab === "custom" ? customDate.end : undefined,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  // Show error toast if there is an error
  if (error) {
    toast.error("Failed to load stats.");
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
        Статистика по предложениям
      </h1>

      {/* Tabs for Today, Month, and Custom Period */}
      <div className="mb-4 flex gap-4">
        <button
          onClick={() => setSelectedTab("today")}
          className={`px-4 py-2 rounded-md ${selectedTab === "today"
              ? "bg-blue-500 text-white dark:bg-blue-700"
              : "bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-white"
            }`}
        >
          Сегодня
        </button>
        <button
          onClick={() => setSelectedTab("month")}
          className={`px-4 py-2 rounded-md ${selectedTab === "month"
              ? "bg-blue-500 text-white dark:bg-blue-700"
              : "bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-white"
            }`}
        >
          Месяц
        </button>
        <button
          onClick={() => setSelectedTab("custom")}
          className={`px-4 py-2 rounded-md ${selectedTab === "custom"
              ? "bg-blue-500 text-white dark:bg-blue-700"
              : "bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-white"
            }`}
        >
          Произвольный период
        </button>
      </div>

      {/* Custom date range inputs if 'Custom Period' tab is selected */}
      {selectedTab === "custom" && (
        <div className="mb-4">
          <label className="text-gray-900 dark:text-white">Дата от:</label>
          <input
            type="date"
            value={customDate.start}
            onChange={(e) =>
              setCustomDate({ ...customDate, start: e.target.value })
            }
            className="border p-2 rounded-md bg-white text-gray-900 dark:bg-gray-800 dark:text-white dark:border-gray-600"
          />
          <label className="ml-4 text-gray-900 dark:text-white">Дата до:</label>
          <input
            type="date"
            value={customDate.end}
            onChange={(e) =>
              setCustomDate({ ...customDate, end: e.target.value })
            }
            className="border p-2 rounded-md bg-white text-gray-900 dark:bg-gray-800 dark:text-white dark:border-gray-600"
          />
        </div>
      )}

      {/* Profile Button */}
      <div className="mb-4">
        <Link
          to="/dashboard"
          className="text-blue-500 underline dark:text-blue-300"
        >
          В профиль
        </Link>
      </div>

      {/* Table */}
      <table className="w-full border border-gray-300 dark:border-gray-700">
        <thead>
          <tr>
            <th className="border px-4 py-2 bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-white">
              Предложения
            </th>
            <th className="border px-4 py-2 bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-white">
              Доход
            </th>
            <th className="border px-4 py-2 bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-white">
              Лайки
            </th>
            <th className="border px-4 py-2 bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-white">
              Рейтинг
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Skeleton loader while loading or re-fetching */}
          {isLoading || isFetching ? (
            <tr>
              <td colSpan={5} className="border px-4 py-2">
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 h-6 bg-gray-200 dark:bg-gray-600 rounded"></div>
                  <div className="flex-1 h-6 bg-gray-200 dark:bg-gray-600 rounded"></div>
                  <div className="flex-1 h-6 bg-gray-200 dark:bg-gray-600 rounded"></div>
                  <div className="flex-1 h-6 bg-gray-200 dark:bg-gray-600 rounded"></div>
                </div>
              </td>
            </tr>
          ) : (
            data?.map((item, idx) => (
              <tr key={idx}>
                <td className="border px-4 py-2">
                  <Link
                    className="text-blue-500 underline"
                    to={`${item.type === "image"
                        ? `/images/${item.id}`
                        : item.type === "digitalproduct"
                          ? `/digital-products/${item.id}`
                          : item.type === "course"
                            ? `/courses/${item.id}`
                            : ""
                      }`}
                  >
                    {item.title}
                  </Link>
                </td>
                <td className="border px-4 py-2">{item.income} $</td>
                <td className="border px-4 py-2">{item.likes}</td>
                <td className="border px-4 py-2">{item.rating}</td>
              </tr>
            ))
          )}  
        </tbody>
      </table>
    </div>
  );
};

export default AssumptionsStatsPage;
