import React from "react";
import { useGetMyPurchasesQuery } from "entities/purchase/purchaseApi";
import Loading from "shared/ui/Loading";
import { Link } from "react-router-dom";

type Props = {};

const PurchasesHistoryPage = (props: Props) => {
  const { data, error, isLoading } = useGetMyPurchasesQuery();

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-500 dark:text-red-400 text-lg">
          Ошибка загрузки истории покупок. Пожалуйста, попробуйте позже.
        </p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-100">
      <div className="container mx-auto py-12 px-6">
        <h1 className="text-3xl font-bold mb-8 text-center">История покупок</h1>
        {data?.length ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg">
              <thead>
                <tr className="border-b border-gray-200 dark:border-gray-700">
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                    Название продукта
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                    Цена
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                    Статус
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                    Дата
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                    Действия
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                    Ссылк на чат
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((purchase, index) => (
                  <tr
                    key={purchase.id}
                    className={`border-b border-gray-200 dark:border-gray-700 ${index % 2 === 0 ? "bg-gray-50 dark:bg-gray-900" : ""
                      }`}
                  >
                    <td className="px-4 py-2">
                      <Link
                        to={`/${purchase.product_type.includes("Image")
                            ? "images"
                            : purchase.product_type.includes("DigitalProduct")
                              ? "digital-products"
                              : purchase.product_type.includes("Course")
                                ? "courses"
                                : ""
                          }/${purchase.product_id}`}
                        className="text-blue-600 dark:text-blue-400 hover:underline"
                      >
                        {purchase.product ? purchase.product.title : "Продукт был удален"}
                      </Link>
                    </td>
                    <td className="px-4 py-2">{purchase.price} USD</td>
                    <td className="px-4 py-2">
                      <span
                        className={`font-semibold ${purchase.status === "completed"
                            ? "text-green-500 dark:text-green-400"
                            : purchase.status === "canceled"
                              ? "text-red-500 dark:text-red-400"
                              : purchase.status === "pending"
                                ? "text-yellow-500 dark:text-yellow-400"
                                : "text-blue-500 dark:text-blue-400"
                          }`}
                      >
                        {purchase.status}
                      </span>
                    </td>
                    <td className="px-4 py-2">
                      {new Date(purchase.created_at).toLocaleDateString(
                        "ru-RU"
                      )}
                    </td>
                    <td className="px-4 py-2">
                      <Link
                        to={`/${purchase.product_type.includes("Image")
                            ? "images"
                            : purchase.product_type.includes("DigitalProduct")
                              ? "digital-products"
                              : purchase.product_type.includes("Course")
                                ? "courses"
                                : ""
                          }/${purchase.product_id}`}
                        className="text-blue-600 dark:text-blue-400 hover:underline"
                      >
                        Подробнее
                      </Link>
                    </td>
                    <td className="px-4 py-2">
                      <Link
                        to={`/dispute-chats/${purchase.id}`}
                        className="text-blue-600 dark:text-blue-400 hover:underline"
                      >
                        Перейти в чат
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center text-lg font-medium mt-16">
            История покупок пуста.
          </p>
        )}
      </div>
    </div>
  );
};

export default PurchasesHistoryPage;
